import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SplashScreen from './SplashScreen.js';
import RecipeList from './RecipeList.js';
import RecipeDetail from './RecipeDetail.js';
import './App.css';
import './index.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const hasLoadedBefore = localStorage.getItem('hasLoadedBefore');
    if (hasLoadedBefore) {
      setIsLoading(false);
    }

    // Set timeout to reset the flag after 10 seconds
    const timer = setTimeout(() => {
      localStorage.removeItem('hasLoadedBefore');
    }, 10000);

    // Add event listener to reset the flag when the app is closed
    const handleBeforeUnload = () => {
      localStorage.removeItem('hasLoadedBefore');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Simulate content loading
    setTimeout(() => {
      setContentLoaded(true);
    }, 1000); // Simulating loading time

    // Cleanup the timer and event listener
    return () => {
      clearTimeout(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  if (isLoading) {
    return <SplashScreen setIsLoading={setIsLoading} />;
  }

  return (
    <Router>
      {contentLoaded ? (
        <Routes>
          <Route path="/" element={<RecipeList />} />
          <Route path="/recipes" element={<RecipeList />} />
          <Route path="/recipes/:id" element={<RecipeDetail />} />
        </Routes>
      ) : (
        <div>Loading...</div>
      )}
    </Router>
  );
}

export default App;
