import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './images/sea-bird.png';
import barsIcon from './images/food/bars.png';
import muffinIcon from './images/food/muffin.png';
import breadIcon from './images/food/bread.png';
import cakeIcon from './images/food/cake.png';
import cookieIcon from './images/food/cookie.png';
import quickBreadIcon from './images/food/quick_bread.png'; // Add icon for Quick Breads
import Footer from './Footer.js';
import { requestWakeLock, releaseWakeLock } from './wakeLock.js';
import allFoodIcon from './images/food/all_food.png';
import bakeryIcon from './images/food/bakery.png';
import restaurantIcon from './images/food/restaurant.png';
import pieIcon from './images/food/pie.png';
import menuIcon from './images/food/menu.jpg';
import { fetchWithCache } from './fetchWithCache.js';

const API_URL = process.env.REACT_APP_API_URL;
console.log(`API URL from the Recipe List: ${API_URL}`);

function RecipeList() {
  const [recipes, setRecipes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [parentCategory, setParentCategory] = useState(null);
  const [grandParentCategory, setGrandParentCategory] = useState(null);
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    requestWakeLock();
    return () => {
      releaseWakeLock();
    };
  }, []);

  useEffect(() => {
    fetchWithCache(`${API_URL}/api/food_categories`)
      .then(data => setCategories(data.data))
      .catch(error => console.error('There has been a problem with your fetch operation:', error));
  }, []);

  useEffect(() => {
    let url = `${API_URL}/api/recipes`;
    if (selectedCategory) {
      url += `?category_id=${selectedCategory}`;
    } else if (parentCategory) {
      url += `?parent_category_id=${parentCategory}`;
    } else if (grandParentCategory) {
      url += `?parent_category_id=${grandParentCategory}`;
    }

    fetchWithCache(url)
      .then(data => {
        const sortedRecipes = data.data.sort((a, b) => a.recipe_name.localeCompare(b.recipe_name));
        setRecipes(sortedRecipes);
        setFadeIn(true);
        setTimeout(() => setFadeIn(false), 500);
      })
      .catch(error => console.error('There has been a problem with your fetch operation:', error));
  }, [selectedCategory, parentCategory, grandParentCategory]);

  const getCategoryIcon = categoryName => {
    switch (categoryName.toLowerCase()) {
      case 'bars': return barsIcon;
      case 'muffins': return muffinIcon;
      case 'bread': return breadIcon;
      case 'cake': return cakeIcon;
      case 'cookies': return cookieIcon;
      case 'pie': return pieIcon;
      case 'quick breads': return quickBreadIcon; // Add case for Quick Breads
      case 'all categories': return allFoodIcon;
      case 'bakery': return bakeryIcon;
      case 'restaurant': return restaurantIcon;
      case 'menu': return menuIcon;
      default: return null;
    }
  };

  const handleGrandParentCategoryClick = grandParent => {
    setGrandParentCategory(grandParent);
    setParentCategory(null);
    setSelectedCategory(null);
  };

  const handleParentCategoryClick = parent => {
    setParentCategory(parent);
    setSelectedCategory(null);
  };

  const handleSubCategoryClick = subcategory => {
    setSelectedCategory(subcategory);
  };

  const grandParentCategories = [
    { id: null, name: 'All Categories' },
    ...categories.filter(category => category.parent_id === null)
  ];

  const parentCategories = categories.filter(category => category.parent_id === grandParentCategory);
  const subCategories = categories.filter(category => category.parent_id === parentCategory);

  return (
    <>
      <div className="logo">
        <img src={logo} alt="logo" />
        <h1>The Seabird Cookbook</h1>
      </div>
      <div>
        <div className="categoryList">
          <h4>Categories</h4>
          <span className="categoryExtra"></span>
          <div className="categoryButtons">
            {grandParentCategories.map(grandParent => (
              <button
                key={grandParent.id}
                onClick={() => handleGrandParentCategoryClick(grandParent.id)}
                className={grandParentCategory === grandParent.id ? 'active' : ''}
              >
            <img src={getCategoryIcon(grandParent.name)} alt={grandParent.name} />
            {grandParent.name}
          </button>
            ))}
          </div>
          {grandParentCategory !== null && (
            <div className="subcategoryButtons">
              {parentCategories.map(parent => (
                <button
                  key={parent.id}
                  onClick={() => handleParentCategoryClick(parent.id)}
                  className={parentCategory === parent.id ? 'active' : ''}
                >
                  <img src={getCategoryIcon(parent.name)} alt={parent.name} />
                  {parent.name}
                </button>
              ))}
            </div>
          )}
          {parentCategory !== null && (
            <div className="subcategoryButtons">
              {subCategories.map(subcategory => (
                <button
                  key={subcategory.id}
                  onClick={() => handleSubCategoryClick(subcategory.id)}
                  className={selectedCategory === subcategory.id ? 'active' : ''}
                >
                  <img src={getCategoryIcon(subcategory.name)} alt={subcategory.name} />
                  {subcategory.name}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className={`recipeList ${fadeIn ? 'fade-in' : ''}`}>
          <h1>Recipes</h1>
          {recipes.map(recipe => (
            <div key={recipe.id}>
              <ul>
                <h2>
                  <Link to={`/recipes/${recipe.id}`}>{recipe.recipe_name}</Link>
                </h2>
              </ul>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default RecipeList;