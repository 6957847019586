const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	window.location.hostname === '[::1]' ||
	window.location.hostname.match(/^127(?:\.\d+){3}$/) ||
	window.location.hostname === 'http://localhost:3003' // Add your custom domain here
  );
  
  export function register(config) {
	if ('serviceWorker' in navigator) {
	  window.addEventListener('load', () => {
		const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
		if (isLocalhost) {
		  // This is running on localhost. Let's check if a service worker still exists or not.
		  checkValidServiceWorker(swUrl, config);
  
		  // Add some additional logging to localhost, pointing developers to the
		  // service worker/PWA documentation.
		  navigator.serviceWorker.ready.then(() => {
			console.log(
			  'This web app is being served cache-first by a service ' +
				'worker. To learn more, visit https://cra.link/PWA'
			);
		  });
		} else {
		  // Is not localhost. Just register service worker
		  registerValidSW(swUrl, config);
		}
	  });
	}
  }
  
  function registerValidSW(swUrl, config) {
	navigator.serviceWorker
	  .register(swUrl)
	  .then((registration) => {
		registration.onupdatefound = () => {
		  const installingWorker = registration.installing;
		  if (installingWorker == null) {
			return;
		  }
		  installingWorker.onstatechange = () => {
			if (installingWorker.state === 'installed') {
			  if (navigator.serviceWorker.controller) {
				console.log(
				  'New content is available and will be used when all ' +
					'tabs for this page are closed. See https://cra.link/PWA.'
				);
  
				// Execute callback
				if (config && config.onUpdate) {
				  config.onUpdate(registration);
				}
			  } else {
				console.log('Content is cached for offline use.');
  
				// Execute callback
				if (config && config.onSuccess) {
				  config.onSuccess(registration);
				}
			  }
			}
		  };
		};
	  })
	  .catch((error) => {
		console.error('Error during service worker registration:', error);
	  });
  }
  
  function checkValidServiceWorker(swUrl, config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl, {
	  headers: { 'Service-Worker': 'script' },
	})
	  .then((response) => {
		// Ensure service worker exists, and that we really are getting a JS file.
		const contentType = response.headers.get('content-type');
		if (
		  response.status === 404 ||
		  (contentType != null && contentType.indexOf('javascript') === -1)
		) {
		  // No service worker found. Probably a different app. Reload the page.
		  navigator.serviceWorker.ready.then((registration) => {
			registration.unregister().then(() => {
			  window.location.reload();
			});
		  });
		} else {
		  // Service worker found. Proceed as normal.
		  registerValidSW(swUrl, config);
		}
	  })
	  .catch((error) => {
		console.log(
		  'No internet connection found. App is running in offline mode.',
		  error
		);
		if (isLocalhost) {
		  // If in localhost, bypass SSL certificate validation
		  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'; // Disable SSL certificate validation
		}
	  });
  }
  
  export function unregister() {
	if ('serviceWorker' in navigator) {
	  navigator.serviceWorker.ready
		.then((registration) => {
		  registration.unregister();
		})
		.catch((error) => {
		  console.error(error.message);
		});
	}
  }